.LoginOauth {
  border: 1px solid #eee;
  width: 350px;
  height: 300px;
  margin: 0 auto;
  margin-top: 50px;

  background-color: #fff;

  padding: 20px;

  .userinfo {

    text-align: center;
    padding-top: 10px;
  }

  .oauthTitle {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }

  .client {
    margin: 10px;
  }
}
@primary-color: var(--ant-color-primary);