.PassportInfo {

  font-size: 14px;
  background: #ffffff;
  padding: 15px 50px 50px;


  @media screen and (min-width: 768px) {
    width: 500px;
    margin: 50px auto 0;
  }

  border-radius: 3px;
  box-shadow: 0 0 5px 1px #eee;


  .subtitle {
    color: #aaa;
    text-align: center;
    font-size: 14px;
  }

  .title {
    font-size: 30px;
    text-align: center;
  }

  .label {
    color: #666;
  }

  .row {
    margin-top: 10px;

    .center {
      margin-top: 5px;
    }


  }
}

@primary-color: var(--ant-color-primary);