.Forget {

  @media screen and (min-width: 768px) {
    padding: 0 20px 20px 20px;
    width: 500px;
    margin: 50px auto 0;
  }
  border-radius: 3px;
  background: #ffffff;
  //box-shadow: 0 0 5px 1px #eee;


  .ant-form-item-label label {
    font-size: 14px;
  }

  .title {
    padding-top: 30px;
    font-size: 30px;
    //text-align: center;
    line-height: 100px;

    @media screen and (min-width: 768px) {
      margin-left: 80px;
    }
  }

  .txt {
    height: 35px;
    line-height: 35px;
  }

  .myform {
    width: 390px;


    .send {
      margin-left: 30px;
    }

    .btn {
      font-size: 16px;
      padding: 5px 20px;
      height: 35px;
    }
  }

  @media screen and (max-width: 767px) {
    .myform {
      padding: 0 60px 20px 40px;
    }
  }


}


@primary-color: var(--ant-color-primary);