// 弹窗
.popup-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
  text-align: center;

  .popup-content {
    position: relative;
    display: inline-block;
    margin: 0 auto;

    .badge-icon-close-login {
      position: absolute;
      right: -20px;
      top: 30px;
      background: #ffffff;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      transition: background-color .2s;

      &:hover {
        background: rgb(240, 240, 240);
      }
    }
  }
}

@primary-color: var(--ant-color-primary);