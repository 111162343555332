.Login {

  width: calc(100vw - 40px);
  margin: 50px auto 0;

  background: #ffffff;
  //@media screen and (min-width: 768px) {
  @media screen and (min-width: 576px) {
    width: 400px;
    margin: 50px auto 0;
  }

  padding-bottom: 10px;
  border-radius: 3px;
  //box-shadow: 0 0 5px 1px #eee;

  .title {
    padding-top: 30px;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
  }

  .we {
    text-align: center;
    margin-left: inherit;
    line-height: 1.5;

  }

  .weScan {
    background-color: @primary-color;
    color: #fff;
  }

  .wechat-tip {
    font-size: 12px;
    line-height: 1.35;
    text-align: center;
    background-color: @primary-color;
    color: #fff;
    padding-bottom: 10px;
  }

  .change {
    font-size: 16px;
  }

  .login-form {
    max-width: 300px;
    margin: 0 auto;
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
    font-size: 18px;
    line-height: 35px;
    height: 40px;
  }

  .txt {
    font-size: 18px;
  }

  .btn {
    font-size: 16px;
  }

  .login-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }

  .img-div {
    width: 180px;
    min-height: 160px;
    overflow: hidden;
    padding: 2px;
    border: 1px solid #eeeeee;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;

    .img {
      width: 100%;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .8);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;

      .icon {
        margin-top: 10px;
        font-size: 50px;
        cursor: pointer;
        margin-left: 60px;
      }

      .font {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .mask-load {
      background: none;

      .mask-load-icon {
        margin-top: 10px;
        font-size: 50px;
        color: rgba(0, 0, 0, .5);
      }
    }
  }

  .weChat-bind {
    padding: 0 50px;

    .title {
      padding-top: 30px;
      font-size: 30px;
      text-align: center;
      line-height: 100px;
    }

    .sendSms {
      //border: 1px solid #1da57a;
      height: 35px;
      font-size: 16px;
    }

    input {
      font-weight: bold;
      font-size: 16px;
    }

    .remark-font {
      font-size: 14px;
      color: #aaa;
      text-align: center;
      margin-bottom: 50px;
    }

    .remark-font2 {
      font-size: 14px;
      color: #aaa;
      text-align: center;
    }


    .button-list {
      height: 40px;
      margin-bottom: 20px;
      font-size: 14px;
    }

  }

}


@primary-color: var(--ant-color-primary);