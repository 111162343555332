//@import '~antd/dist/antd.less';
//@import '~antd/dist/antd.compact.less';

@import "~antd/dist/reset.css";

// 限制其仅作用于特定的父级class：prism-default-theme、prism-tomorrow-theme 支持在同一个项目中，使用不同的主题
// @import "~prismjs/themes/prism.css";
// @import "~prismjs/themes/prism-tomorrow.css";
@import "./prism.less";
@import "./prism-tomorrow.less";

html, body, #root {
  height: 100%;
}

.my-antd-app {
  //height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

// 透明弹层
.my-transparent .ant-modal-content {
  background-color: initial;
  box-shadow: none;
}

//a{
//  color: var(--ant-color-primary)
//}

.page-header {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: rgba(56, 56, 56, 0.26) 0 0 18px 0;

  //
  //@media screen and (max-width: 767px) {
  //  padding: 0;
  //}
  //
  //// 平板宽度
  //@media (min-width: 768px) and (max-width: 991.98px) {
  //  padding: 0 30px;
  //}

  .page-header-div {

    //@media screen and (min-width: 768px) {
    //  width: 1180px;
    //  margin: 0 auto;
    //}
    //
    //// 平板宽度
    //@media (min-width: 768px) and (max-width: 991.98px) {
    //  width: 100%;
    //  margin: 0 auto;
    //}

    background-color: #fff;

    .page-header-div-link {

      //@media screen and (max-width: 991.98px) {
      //  //display: none; // 手机屏和平板不显示
      //  white-space: nowrap;
      //
      //  .link-img {
      //    padding: 7px;
      //  }
      //}

      .link-img {
        height: 60px;
      }

      .slogan {
        margin-left: 13px;
        font-size: 18px;
        font-family: "Microsoft Yahei";
        position: relative;
        top: 5px;
        border-left: 2px solid #f0f1f5;
        padding-left: 10px;
        color: #525c65;
      }

    }


    .mine-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      float: right;

      .top-button-div {

        //@media screen and (min-width: 768px) {
        //  margin-right: 50px;
        //}

        .top-button-list {
          display: inline-block;
          margin-right: 30px;

          .top-button {
            color: #525c65;
            font-size: 16px;
            font-weight: bold;
            padding: 5px;
          }


          .top-button.active {
            border-bottom: 4px solid @primary-color;
          }

        }
      }

      // 我的课程
      .mine-select-div {
        margin-right: 20px;

        .ant-dropdown-link {
          font-size: 14px;
          color: #525c65;

          .icon {
            font-size: 12px;
          }
        }
      }

      .mine-content {
        display: flex;
        flex-direction: row;
        margin-right: 20px;
        align-items: center;
        z-index: 99999;

        // 通知
        .mine-notice {
          margin-right: 30px;

          .mine-notice-badge {
            box-shadow: none;
          }

          .icon {
            font-size: 20px;
            color: #666;
            cursor: pointer;
          }
        }

        // 头像
        .mine-header-img {
          transform: translateY(-5px);
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          &:hover .drop-down-div {
            opacity: 1;
            transform: scale(1);
            transform-origin: 50% 0;
          }

          .drop-down-div {
            padding: 17px 5px 5px;
            position: absolute;
            left: -40px;
            top: 38px;
            transition: all .3s;
            transform: scale(0);
            transform-origin: 50% 0;
            opacity: 0;

            //@media (max-width: 767.98px) {
            //  left: -70px;
            //}

            .arrow {
              width: 20px;
              height: 20px;
              background: #ffffff;
              position: absolute;
              top: 10px;
              left: 50px;
              box-shadow: -2px -2px 4px -4px rgba(0, 0, 0, 0.8);
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);

              //@media (max-width: 767.98px) {
              //  left: 80px;
              //}
            }

            .drop-down-content {
              background: #ffffff;
              box-shadow: 0 0 6px -4px rgba(0, 0, 0, .8);
              padding: 20px 10px 10px;
              border-radius: 5px;
              overflow: hidden;

              .avatar-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;

                .avatar-item-button {
                  font-size: 16px;
                  color: #525c65;

                  &:hover {
                    color: @primary-color;
                  }
                }

                .avatar-item-button-quit {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 我的课程下拉列表
.mine-content-drop-down {
  padding: 0;
  border-radius: 5px;

  .mine-content-drop-down-item {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    width: 350px;
    max-width: 350px;
    padding: 20px;
    overflow: hidden;

    .drop-down-item-left-div {
      min-width: 120px;
      max-width: 120px;
      max-height: 80px;
      overflow: hidden;

      .drop-down-item-left-img {
        width: 100%;
        font-size: 12px;
        color: #ccc;
        text-indent: 2px;
      }
    }

    .drop-down-item-right-div {
      padding: 0 10px;
      flex: 1;
      overflow: hidden;

      .right-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #525c65;
      }

      .right-status {
        font-size: 14px;
        color: #999;
        margin-top: 25px;
      }
    }
  }

  .mine-content-drop-down-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0 20px;

    .button {
      font-size: 16px;
      color: #a4a4a4;

      &:hover {
        color: @primary-color;
      }
    }
  }
}

// 通知内容
.notice-content-drop-down {
  border-radius: 5px;
  overflow: hidden;
  max-width: 150px;
  width: 150px;

  .notice-drop-down-item {
    padding: 10px 20px;
    font-size: 16px;

    .item-font {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 0;
      color: #525c65;
      cursor: pointer;

      .item-font-badge {
        box-shadow: none;
      }
    }
  }
}

// 个人头像下拉列表
.avatar-content-drop-down {
  padding: 20px 10px 0;
  border-radius: 5px;
  overflow: hidden;

  .avatar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .avatar-item-button {
      font-size: 16px;
      color: #525c65;

      &:hover {
        color: @primary-color;
      }
    }

    .avatar-item-button-quit {
      font-size: 14px;
    }
  }
}

// 微信二维码
.WeChat-QR-Code {
  padding: 5px;
  width: 150px;

  .img {
    width: 100%;
  }
}

.footer-box {

  //display: none; // 手机屏和平板，不显示


  //min-width: 1200px;
  //min-height: 200px;
  background: #333333;
  color: #999999;
  flex-direction: column;
  padding: 0;

  .footer-content {
    //width: 1200px;
    margin: 0 auto;
    flex-direction: row;
    flex: 1;
    padding: 40px 0;

    // 平板宽度
    //@media (min-width: 768px) and (max-width: 991.98px) {
    //  width: 100%;
    //}

    display: none;
    /* lg 视口宽度最小需要992才生效，即 >= 992px */
    @media screen and (min-width: 992px) {
      // pc 才显示
      display: flex;
    }


    .footer-left-div {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 50px;

      // 平板宽度
      //@media (min-width: 768px) and (max-width: 991.98px) {
      //  width: 300px;
      //
      //}

      .img-div {
        padding: 1px;

        .img {
          width: 200px;
        }
      }

      .icon-div {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 10px;

        .icon-img-div {
          flex: 1;
          background: rgba(255, 255, 255, .3);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 30px;
          max-width: 30px;
          height: 30px;
          border-radius: 100%;
          cursor: pointer;
          margin-right: 10px;
          transition: background-color .2s;

          &:hover {
            background: rgba(255, 255, 255, .4);
          }

          .icon-img {
            width: 20px;
          }
        }
      }
    }

    .footer-content-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .footer-content-list-title {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .footer-content-list-content {
        .list-font {
          font-size: 14px;
          cursor: pointer;
          transition: color .2s;
          color: #999999;

          &:hover {
            //color: rgb(245, 245, 245);
            color: #f5f5f5;
          }
        }
      }
    }
  }

  .copy-div {
    height: 50px;
    background: #181818;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    .copy-div-font {
      display: inline;
      color: #fff;
      padding-left: 5px;
    }
  }
}

.LoginLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;

}

@primary-color: var(--ant-color-primary);